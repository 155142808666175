import React, { useEffect } from "react";
import { useDencrypt } from "use-dencrypt-effect";

const decryptOptions = {
  chars: [
    "-",
    ".",
    "/",
    "*",
    "!",
    "?",
    "#",
    "%",
    "&",
    "@",
    "$",
    "€",
    "(",
    ")",
    "[",
    "]",
    "{",
    "}",
    "<",
    ">",
    "~",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  interval: 50,
};

export const JobDecrypt = (props) => {
  const jobRoles = props.text;
  const { result, dencrypt } = useDencrypt(decryptOptions);

  useEffect(() => {
    let i = 0;

    const action = setInterval(() => {
      dencrypt(jobRoles[i]);

      i = i === jobRoles.length - 1 ? 0 : i + 1;
    }, 2250);

    return () => clearInterval(action);
  }, []);

  return (
    <p>
      {result}
      {" "}
    </p>
  );
};
